import React from 'react';
import { HeaderTop } from '../HeaderTop';
import { HeaderBottom } from '../HeaderBottom';
import './Header.css';

export const Header = React.forwardRef((props, ref) => (
    <header className="w-100 position-fixed" ref={ref}>
        <HeaderTop {...props}/>
        <hr />
        <HeaderBottom {...props}/>
    </header>
));