import { http } from 'api/http';

export const CART_ENDPOINT = "cart";
export const CREATE_EMPTY_CART = "cart/create";

export const createEmptyCart = (payload) => http.post(CREATE_EMPTY_CART,payload);

export const getCartById = (id) => http.get(CART_ENDPOINT + "/" + id);

export const addProductToCart = (id, payload) => http.post(CART_ENDPOINT + "/" + id + "/items",payload);

export const deleteProductFromCart = (cartId,cartItemId) => http.delete(CART_ENDPOINT + "/" + cartId +"/items/" + cartItemId);