import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { SectionContainer } from 'components/Containers';
import { ProductCard } from 'components/Product';
import { Entity } from 'context';

export const CartItems = () => {
    const entity = useContext(Entity);
    const entitySingular = entity.slice(0,-1)
    const cart = useSelector(state => state.cart[entity]);

    return (
        <SectionContainer title="My Cart">
            {
                cart.products.length > 0 ? 
                <div className="w-100 d-flex flex-wrap">
                    <div className="col-12 p-1 d-lg-none mb-2">
                        <h4>Cart Items</h4>
                    </div>
                    <div className="col-lg-7 col-12 d-flex flex-column mb-3 p-1">
                        {
                            cart.products.map((product, i) => (
                                <div className="w-100 mb-2" key={'product_card_' + i}>
                                    <ProductCard
                                        productId={product[entitySingular].id}
                                        name={product[entitySingular].name}
                                        manufacturer={product[entitySingular].manufacturer}
                                        price={product[entitySingular].price_per_strip || product[entitySingular].price}
                                        image={product[entitySingular].image_url || product[entitySingular].image}
                                        rxRequired={product[entitySingular].rx_required}
                                        viewType="list"
                                    />
                                </div>
                            ))
                        }
                    </div>
                    <div className="col-12 d-lg-none mb-2 p-1">
                        <h4>Summary</h4>
                    </div>
                    <div className="col-lg-5 col-12 mb-3 p-1">
                        <div className="w-100 p-lg-5 p-4 bg-white">
                            <h5 className="mb-4 font-weight-bold">Subtotal: ({cart.products.length} items)</h5>
                            {
                                cart.products.map((product, i) => (
                                    <div className="row">
                                        <div className="col-md-10 col-sm-10">
                                            <p className="mb-1 f-14" key={'product_subtotal_' + i}>
                                                {product[entitySingular].name} ({product.quantity})
                                            </p>
                                        </div>
                                        <div className="col-md-2 col-sm-2">
                                            <p className="mb-1 f-14" key={'product_subtotal_' + i}>
                                                <span className="float-right">₹ {product.price}</span>
                                            </p>
                                        </div>
                                    </div>
                                ))
                            }
                            <hr className="mt-3 mb-3" />
                            <p className="font-weight-bold mb-4">Total<span className="float-right">₹ {cart.sub_total}</span></p>
                            <Link to={"/" + entity + "/checkout"} className="btn btn-block bg-theme text-light">
                                Proceed to Checkout
                            </Link>
                        </div>
                    </div>
                </div> : 
                <p className="text-center pt-5 pb-5">Your cart is empty</p>
            }
        </SectionContainer>
    )
}