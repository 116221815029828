import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Loader } from 'components/Core';
import { SectionContainer } from 'components/Containers';
import { ProductTestCard } from 'components/Product';
import { fetchTests, setLoading, setCurrentPage, resetState } from 'redux/Test';
import { useQuery, useWindowSize } from 'helpers';

export const Tests = () => {
    const query = useQuery();
    const queryString = query.toString();
    const dispatch = useDispatch();
    const name = query.get("name");
    const tests = useSelector(state => state.tests.default.data);
    const loading = useSelector(state => state.tests.default.loading);
    const currentPage = useSelector(state => state.tests.default.current_page);
    const totalPage = useSelector(state => state.tests.default.total_page);
    const dataQueryString = useSelector(state => state.tests.default.search_query_string);
    const [viewType, setViewType] = useState("grid");
    const windowSize = useWindowSize();

    useEffect(() => {
        if (windowSize.width < 576) {
            setViewType("list");
        } else {
            setViewType("grid");
        }
    }, [windowSize]);

    useEffect(() => {
        if (loading) {
            dispatch(fetchTests({
                page: currentPage,
                rx_dispatch_type: 'default',
                search_query_string: queryString,
                name
            }));
        }
    }, [name, dispatch, loading, queryString, currentPage]);

    useEffect(() => {
        if (!loading && queryString !== dataQueryString) {
            dispatch(resetState({
                rx_dispatch_type: 'default'
            }));
            dispatch(setLoading({
                rx_dispatch_type: 'default',
                loading: true
            }));
        }
    }, [loading, queryString, dataQueryString, dispatch])

    return (
        <SectionContainer title={query.get("title") || "Tests"}>
            {
                tests.length > 0 &&
                <div className="w-100 d-flex flex-wrap">
                    {
                        tests.map((test, i) => (
                            <div className="col-sm-4 col-md-3 p-1" key={i}>
                                <ProductTestCard
                                    productId={test.id}
                                    name={test.name}
                                    image={test.image}
                                    price={test.price}
                                    viewType={viewType}
                                    description={test.short_disc}
                                />
                            </div>
                        ))
                    }
                </div>
            }
            { loading && <Loader />}
            {
                !loading && currentPage < totalPage &&
                <div className="w-100 d-flex justify-content-center pt-5 pb-3">
                    <button
                        className="btn btn-primary bg-theme"
                        onClick={() => {
                            dispatch(setCurrentPage({
                                rx_dispatch_type: 'default',
                                current_page: currentPage + 1
                            }))
                            dispatch(setLoading({
                                rx_dispatch_type: 'default',
                                loading: true
                            }))
                        }}>
                        Load More</button>
                </div>
            }
            {
                !loading && tests.length === 0 &&
                <p className="text-center pt-5 pb-5">No tests found for your search criteria</p>
            }
        </SectionContainer>
    );
}