import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { me, ME_ENDPOINT  } from 'api/auth';

export const fetchProfile = createAsyncThunk(ME_ENDPOINT, async () => {
    const response = await me();
    return response;
});


const initialState = {
    loading: true,
    access_token: "",
    refresh_token: "",
    profile: null
};

export const MeSlice = createSlice({
    name: 'me',
    initialState,
    reducers: {
        setLoading: (state,action) => ({
            ...state,
            loading: action.payload
        }),
        setAccessToken: (state,action) => {
            localStorage.access_token = action.payload;
            return {
                ...state,
                access_token: action.payload
            };
        },
        setRefreshToken: (state,action) => {
            localStorage.refresh_token = action.payload;
            return {
                ...state,
                refresh_token: action.payload
            }
        },
        logout: () => {
            localStorage.removeItem("access_token");
            localStorage.removeItem("refresh_token");
            return initialState;
        }
    },
    extraReducers: {
        [fetchProfile.pending]: (state) => {
            state.loading = true;
        },
        [fetchProfile.fulfilled]: (state, action) => {
            state.loading = false;
            state.profile = action.payload;
        },
        [fetchProfile.rejected]: (state) => {
            state.loading = false;
            state.access_token = null;
            state.refresh_token = null;
            state.profile = null;
            localStorage.removeItem("access_token");
            localStorage.removeItem("refresh_token");
        }
    }
});

export const { setAccessToken, setRefreshToken, logout } = MeSlice.actions;

export const MeReducer = MeSlice.reducer;