import React from 'react'
import './FooterTop.scss';
import { Button } from 'react-bootstrap';

export function FooterTop() {
    return (
        <footer className="footer">
            {/* Footer Top */}
            <div className="footer-top">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-5 col-md-6">
                    {/* Footer Widget */}
                    <div className="footer-widget footer-about">
                      <div 
                        className="footer-logo mb-2"
                      >
                        <img src={process.env.PUBLIC_URL+"/assets/img/shadow-logo.png"} alt="logo" style={{maxWidth: "200px", height: "auto", marginLeft:"-15px"}} />
                      </div>
                      <div className="footer-about-content">
                        <p>
                        emedicare provides you health services at affordable prices and makes it accessible to everyone. Our aim is to empowering our users with online booking Doctors appointments, purchase online medicines, diagnostic and maintain your health records. Whether you choose to interact with emedicare online, on the phone, or through other channels, our goal is to make sure you get best services and information to make better healthcare decisions about you and your family health.
                        </p>
                      </div>
                    </div>
                    {/* /Footer Widget */}
                  </div>
                  
                  <div className="col-lg-2 col-md-6">
                    {/* Footer Widget */}
                    <div className="footer-widget footer-menu">
                      <h2 className="footer-title">For Patients</h2>
                      <ul>
                        <li><a href={process.env.REACT_APP_CUSTOMER_APP_URL+"/login"}>Login</a></li>
                        <li><a href={process.env.REACT_APP_CUSTOMER_APP_URL+"/signup"}>Register</a></li>
                        {/* <li><a >Appointments</a></li>
                        <li><a >Patient Dashboard</a></li> */}
                      </ul>
                    </div>
                    {/* /Footer Widget */}
                  </div>
                  <div className="col-lg-2 col-md-6">
                    {/* Footer Widget */}
                    <div className="footer-widget footer-menu">
                      <h2 className="footer-title">For Doctors</h2>
                      <ul>
                        <li><a href={process.env.REACT_APP_CUSTOMER_APP_URL+"/login"}>Login</a></li>
                        <li><a href={process.env.REACT_APP_CUSTOMER_APP_URL+"/signup"}>Register</a></li>
                      </ul>
                    </div>
                    {/* /Footer Widget */}
                  </div>
                  <div className="col-lg-3 col-md-6">
                    {/* Footer Widget */}
                    <div className="footer-widget footer-contact">
                      <h2 className="footer-title">Contact Us</h2>
                      <div className="footer-contact-info">
                        <div className="footer-address">
                          <p> Emaar Gurgaon Greens,<br />  Sector 102, Gurugram </p>
                        </div>
                        <p>
                          01247174372
                        </p>
                        <p>
                          99999075725
                        </p>
                        <p className="mb-0">
                          support@emedicare.in
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Footer Top */}
            {/* Footer Bottom */}
            <div className="footer-bottom">
              <div className="container-fluid">
                <div className="copyright">
                  <div className="row">
                    <div className="col-md-4 col-lg-4">
                      <div className="copyright-text">
                        <p className="mb-0">© {new Date().getFullYear()} emedicare. All rights reserved.</p>
                      </div>
                    </div>
                    <div className="col-md-8 col-lg-8">
                      {/* Copyright Menu */}
                      <div className="copyright-menu">
                        <ul className="policy-menu">
                          <li><a href={process.env.REACT_APP_CUSTOMER_APP_URL+"/about-us"} rel="noreferrer" target="_blank">About Us</a></li>
                          <li><a href={process.env.REACT_APP_CUSTOMER_APP_URL+"/terms-and-conditions"} rel="noreferrer" target="_blank">Terms and Conditions</a></li>
                          <li><a href={process.env.REACT_APP_CUSTOMER_APP_URL+"/policy"} rel="noreferrer" target="_blank">Privacy Policy</a></li>
                          <li><Button variant="link" style={{color:"white"}} onClick={() => {
                            window.Beacon('open')
                          }}>Contact us</Button></li>
                        </ul>
                      </div>
                      {/* /Copyright Menu */}
                    </div>
                  </div>
                </div>
                {/* /Copyright */}
              </div>
            </div>
            {/* /Footer Bottom */}
        </footer>
    )
}
