import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { 
    CART_ENDPOINT,
    CREATE_EMPTY_CART, 
    createEmptyCart as createEmptyCartRequest, 
    getCartById,
    addProductToCart,
    deleteProductFromCart
} from 'api/cart';

export const createEmptyCart = createAsyncThunk(CREATE_EMPTY_CART, async (payload) => {
    const response = await createEmptyCartRequest(payload);
    return {
        rx_dispatch_type: payload.rx_dispatch_type,
        response
    };
});

export const fetchCartDetails = createAsyncThunk(CART_ENDPOINT + "/{id}", async (payload) => {
    const response = await getCartById(payload.id);
    return {
        rx_dispatch_type: payload.rx_dispatch_type,
        response
    };
});

export const updateCartProduct = createAsyncThunk(CART_ENDPOINT + "/items", async (payload) => {
    const response = await addProductToCart(payload.cart_id,payload);
    return {
        rx_dispatch_type: payload.rx_dispatch_type,
        response
    };
});

export const deleteCartProduct = createAsyncThunk(CART_ENDPOINT + "/{cartId}/items/{cartItemId}", async (payload) => {
    const response = await deleteProductFromCart(payload.cart_id, payload.cart_item_id);
    return {
        rx_dispatch_type: payload.rx_dispatch_type,
        response
    };
});

const initialState = {
    loading: true,
    cart_id: null,
    sub_total: 0,
    count: 0,
    products: []
};

export const CartSlice = createSlice({
    name: 'cartSlice',
    initialState: {
        medicines: initialState,
        tests: initialState
    },
    reducers: {
        initializeCartId: (state,action) => {
            state[action.payload.rx_dispatch_type].cart_id = action.payload.cart_id;
            return state
        },
        resetCart: (state,action) => {
            localStorage.removeItem(action.payload.rx_dispatch_type + "_cart_id");
            state[action.payload.rx_dispatch_type] = initialState;
            return state;
        }
    },
    extraReducers: {
        [createEmptyCart.pending]: (state,action) => {
            state[action.meta.arg.rx_dispatch_type].cart_id = null;
            state[action.meta.arg.rx_dispatch_type].count = 0;
            state[action.meta.arg.rx_dispatch_type].sub_total = 0;
            state[action.meta.arg.rx_dispatch_type].products = [];
        },
        [createEmptyCart.fulfilled]: (state, action) => {
            localStorage[action.payload.rx_dispatch_type + "_cart_id"] = action.payload.response.cart_id;
            state[action.payload.rx_dispatch_type].cart_id = action.payload.response.cart_id;
        },
        [fetchCartDetails.pending]: (state,action) => {
            state[action.meta.arg.rx_dispatch_type].loading = true;
        },
        [fetchCartDetails.fulfilled]: (state, action) => {
            state[action.payload.rx_dispatch_type].loading = false;
            state[action.payload.rx_dispatch_type].count = action.payload.response.cart_items_count;
            state[action.payload.rx_dispatch_type].sub_total = action.payload.response.subtotal;
            state[action.payload.rx_dispatch_type].products = action.payload.response.cart_items;
        },
        [fetchCartDetails.rejected]: (state,action) => {
            localStorage.removeItem(action.meta.arg.rx_dispatch_type + "_cart_id");
            state[action.meta.arg.rx_dispatch_type].loading = false;
            state[action.meta.arg.rx_dispatch_type].cart_id = null;
            state[action.meta.arg.rx_dispatch_type].sub_total = 0;
            state[action.meta.arg.rx_dispatch_type].count = 0;
            state[action.meta.arg.rx_dispatch_type].products = [];
        },
        [updateCartProduct.fulfilled]: (state,action) => {
            console.log(action);
            state[action.payload.rx_dispatch_type].loading = true;
        },
        [deleteCartProduct.fulfilled]: (state,action) => {
            state[action.payload.rx_dispatch_type].loading = true;
        }
    }
});

export const { initializeCartId, resetCart } = CartSlice.actions;
export const CartReducer = CartSlice.reducer;