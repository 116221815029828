import React, { useEffect, useState, useContext, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Search } from 'components/Forms';
import { ProductCard } from 'components/Product';
import { Loader } from 'components/Core';
import { Entity } from 'context';
import { fetchMedicines, resetState as resetMedicineState } from 'redux/Medicine';
import { fetchTests, resetState as resetTestState } from 'redux/Test';
import { Help ,Phone, QueryBuilder } from '@material-ui/icons';
import { useOuterClick } from 'helpers';
import logo from 'images/logo2.png'
import './HeaderTop.scss';

export const HeaderTop = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const entity = useContext(Entity);
    const [search,setSearch] = useState('');
    const products = useSelector(state => state[entity]['search_' + entity].data);
    const productsLoading = useSelector(state => state[entity]['search_' + entity].loading);

    const innerRef = useOuterClick(() => {
        if(search){
            setSearch('');
        }
    });

    const updateSearchData = useMemo(() => ({
        medicines: {
            reset: () => dispatch(resetMedicineState({
                rx_dispatch_type: "search_medicines"
            })),
            fetch: (search) => dispatch(fetchMedicines({
                rx_dispatch_type: "search_medicines",
                name: search
            }))
        },
        tests: {
            reset: () => dispatch(resetTestState({
                rx_dispatch_type: "search_tests"
            })),
            fetch: search => dispatch(fetchTests({
                rx_dispatch_type: "search_tests",
                name: search
            }))
        }
    }),[dispatch]);

    useEffect(() => {
        updateSearchData[entity].reset();
        if(search){
            updateSearchData[entity].fetch(search);
        }
    }, [search, updateSearchData,entity]);

    useEffect(() => {
        setSearch('');
    },[location])

    return (
        <div className="w-100 d-flex flex-wrap align-items-center justify-content-center header-top pt-2 pb-2">
            <div className="col-12 mb-2 mb-md-0 col-md-3 d-flex justify-content-center justify-content-md-start">
                <div className="col-md-10 col-5 p-0">
                    <a href={process.env.REACT_APP_CUSTOMER_APP_URL}>
                        <img src={logo} alt="logo" className="cursor-pointer col-12 col-md-11 col-lg-10"/>
                    </a>
                </div>
            </div>
            <div className="col-11 col-md-7 col-lg-5 mb-2 mb-md-0" ref={innerRef}>
                <div className="w-100 position-relative search-container">
                    <Search
                        placeholder={"Search for " + entity}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        onKeyDown={e => {
                            if (e.key === 'Enter') {
                                history.push("/" + entity + "/search?title=" + entity + "&name=" + e.target.value);
                            }
                        }}
                    />
                    <div className="w-100 position-absolute bg-white search-suggestion" style = {{overflow:"auto", maxHeight:"80vh" }}>
                        {
                            productsLoading && <Loader />
                        }
                        {
                            products.slice(0, 100).map((product, i) => (
                                <ProductCard
                                    key={'product_card_' + i}
                                    productId={product.id}
                                    name={product.name}
                                    manufacturer={product.manufacturer}
                                    image={product.image_url || product.image}
                                    price={product.price_per_strip || product.price}
                                    rxRequired={product.rx_required}
                                    viewType="list"
                                />
                            ))
                        }
                    </div>
                </div>
            </div>
            <div className="col-4 d-none d-lg-block">
                <div className="w-100 d-flex justify-content-end support-container">
                    <span className="pr-4 pl-4"><Phone className="support-icons mr-1" /><span className="word-nowrap">1800-000-000</span></span>
                    <span className="pr-4 pl-4"><QueryBuilder className="support-icons mr-1" />Offers</span>
                    <span className="pr-4 pl-4"><Help className="support-icons mr-1" />Help</span>
                </div>
            </div>
        </div>
    )
};