import { useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useQuery = () => new URLSearchParams(useLocation().search);

const getTimezoneInstance = Intl.DateTimeFormat().resolvedOptions().timeZone;
export const timezone = getTimezoneInstance === "Asia/Calcutta" ? "Asia/Kolkata" : getTimezoneInstance;

export const setUser = (user) => {
    localStorage.access_token = user.access_token;
    localStorage.refresh_token = user.refresh_token;
}

export const clearUser = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
}

export const useOuterClick = (callback) => {
    const callbackRef = useRef(); 
    const innerRef = useRef(); 

    useEffect(() => { callbackRef.current = callback; });
    useEffect(() => {
        document.addEventListener("click", handleClick);
        return () => document.removeEventListener("click", handleClick);
        function handleClick(e) {
            if (innerRef.current && callbackRef.current &&
                !innerRef.current.contains(e.target)
            ) callbackRef.current(e);
        }
    }, []); 

    return innerRef; 
}

export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowSize;
}