import React from 'react';
import { Search as SearchIcon } from '@material-ui/icons';
import './Search.scss';

export const Search = (props = {}) => {
    return (
        <div className="search-container position-relative w-100">
            <input type="text" className="w-100" {...props} autoComplete="new-password"/>
            <SearchIcon className="search-icon" />
        </div>
    );
}