import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import App from './App';
import store from './store';
import { Provider } from 'react-redux';
import { ToastProvider } from 'react-toast-notifications';

import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

ReactDOM.render(
  <Provider store={store}>
    <ToastProvider autoDismiss>
      <Router>
        <App />
      </Router>
    </ToastProvider>
  </Provider>,
  document.getElementById('root')
);