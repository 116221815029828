import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { SectionContainer } from 'components/Containers';
import { CategoryCarousel } from 'components/Category';
import { ProductCarousel } from 'components/Product';
import { Loader } from 'components/Core';
import { show as showCategory } from 'redux/Category';
import { fetchMedicines } from 'redux/Medicine';

export const Home = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const categoriesLoading = useSelector(state => state.categories.loading);
    const categories = useSelector(state => state.categories.data);
    const previewMedicinesLoading = useSelector(state => state.medicines.home_medicine_preview.loading);
    const previewMedicines = useSelector(state => state.medicines.home_medicine_preview.data);
    const previewRandomCategoryMedicinesLoading = useSelector(state => state.medicines.home_medicine_random_preview.loading);
    const previewRandomCategoryMedicines = useSelector(state => state.medicines.home_medicine_random_preview.data);

    useEffect(() => {
        if(previewMedicinesLoading){
            dispatch(fetchMedicines({
                page: 1,
                rx_dispatch_type: 'home_medicine_preview'
            }));
        }
    }, [previewMedicinesLoading,dispatch]);

    useEffect(() => {
        if (previewRandomCategoryMedicinesLoading && categories.length > 0){
            dispatch(fetchMedicines({
                page: 1,
                category: categories[0].name,
                rx_dispatch_type: 'home_medicine_random_preview'
            }));
        }
    }, [previewRandomCategoryMedicinesLoading,dispatch, categories.length]);

    return (
        <>
            {
                !categoriesLoading &&
                <SectionContainer
                    title="Categories"
                    action={() => dispatch(showCategory())}
                >
                    <CategoryCarousel categories={categories} />
                </SectionContainer>
            }
            {
                !previewMedicinesLoading &&
                <SectionContainer
                    title="Medicines"
                    action={() => history.push("/medicines/search?title=Medicines")}
                >
                    <ProductCarousel products={previewMedicines} />
                </SectionContainer>
            }
            {
                !previewRandomCategoryMedicinesLoading &&
                categories.length > 0 &&
                <SectionContainer
                    title={categories[0].name}
                    action={() => history.push("/medicines/search?title=" + categories[0].name +"&category=" + categories[0].name)}
                >
                    <ProductCarousel products={previewRandomCategoryMedicines} />
                </SectionContainer>
            }
            {
                (categoriesLoading || previewMedicinesLoading || previewRandomCategoryMedicinesLoading) &&
                <Loader />
            }
        </>
    );
}