import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { hide as hideCategory } from 'redux/Category';
import { CategoryCard } from 'components/Category';
import './CategoryPopup.scss';

export const CategoryPopup = ({categories, showCategoryPopup}) => {
    const dispatch = useDispatch();

    return (
        <Modal backdrop="static" show={showCategoryPopup} onHide={() => dispatch(hideCategory())}>
            <Modal.Header closeButton bsPrefix="category-modal-header text-light d-flex justify-content-between pt-4 pb-4 pr-3 pl-3">
                <Modal.Title as="p">Select Category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="w-100 d-flex flex-wrap">
                    {
                        categories.map((category,i) => (
                            <div className="col-6 col-sm-4" key={"category_" + i}>
                                <CategoryCard category={category.name} image={category.image_url} />
                            </div>
                        ))
                    }
                </div>
            </Modal.Body>
        </Modal>
    );
}