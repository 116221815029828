import React,{ useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import { SectionContainer } from 'components/Containers';
import { LoginAndRegister, UploadPrescription, GenerateQuote } from 'pages/Checkout';
import { Entity } from 'context';

export const CheckoutPage = () => {
    const history = useHistory();
    const [activeStep, setActiveStep] = useState(0);
    const [prescription, setPrescription] = useState();
    const entity = useContext(Entity);
    const cart = useSelector(state => state.cart[entity]);

    const steps = ['Login/Signup', 'Upload Prescription', 'Generate Quote'];

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    useEffect(() => {
        if (cart && cart.products.length === 0 && activeStep === 0){
            history.replace("/" + entity + "/cart");
        }
    }, [cart, history, activeStep, entity]);

    return (
        <SectionContainer title="Checkout">
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => (
                    <Step key={index}>
                        <StepLabel>{label}</StepLabel>
                        <StepContent>
                            <div className="col-12 col-lg-6">
                                {
                                    activeStep === 0 && 
                                    <LoginAndRegister 
                                        goToUploadPrescription={handleNext} 
                                    />
                                }
                                {
                                    activeStep === 1 && 
                                    <UploadPrescription 
                                        goToLoginAndRegister={handleBack} 
                                        goToGenerateQuote={handleNext}
                                        setPrescription={(file) => setPrescription(file)} 
                                        prescription={prescription}
                                    />
                                }
                                {
                                    activeStep === 2 && 
                                    <GenerateQuote 
                                        goToUploadPrescription={handleBack} 
                                        prescription={prescription}
                                    />
                                }
                            </div>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
        </SectionContainer>
    );
}

