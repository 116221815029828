import { http } from 'api/http';
import { timezone } from 'helpers';

export const LOGIN_ENDPOINT = "/oauth/login";
export const REGISTER_ENDPOINT = "/oauth/ecommerce/register"
export const VERIFY_OTP = "/oauth/otp/verify/email";
export const RESEND_EMAIL_OTP = "/oauth/otp/resend/email";
export const ME_ENDPOINT = "/me";

export const login = (payload) => http.post(LOGIN_ENDPOINT,{
    ...payload,
    timezone
});

export const register = (payload) => http.post(REGISTER_ENDPOINT,payload);

export const verifyOtp = (payload) => http.post(VERIFY_OTP, payload);

export const resendEmailOtp = (payload) => http.post(RESEND_EMAIL_OTP,payload);

export const me = () => http.get(ME_ENDPOINT);