import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { CheckCircle } from '@material-ui/icons';
import { Entity } from 'context';

export const CheckoutSuccess = () => {
    const { quoteId } = useParams();
    const entity = useContext(Entity);
    console.log("Entity = ", entity);
    const sendQuoteTo = {
        medicines: "pharmacies",
        tests: "laboratories"
    }

    const redirectToPatientDashboard = () => {
        const redirectUrl = process.env.REACT_APP_CUSTOMER_APP_URL + "/redirect";
        window.location.href = redirectUrl + "?token=" + localStorage.access_token + "&redirect_url=/patient/prescriptions/" + quoteId + "/quote/checkout";
    }

    return (
        <div className="w-100 d-flex justify-content-center p-5">
            <div className="col-lg-5 col-md-8 col-sm-10 col-11 bg-white p-lg-5 p-4">
                <p className="text-center pb-3"><CheckCircle className="text-success" style={{ width: "60px", height: "60px" }} /></p>
                
                {entity === 'medicines' ? (
                    <div>
                        <h5 className="text-center font-weight-bold pt-2 pb-2">Well, Now Let's find a Nearby Pharmacy to Send This Order!</h5>
                        <h5 className="text-center font-weight-light pt-2 pb-2">Find one or more pharmacies nearest to your locality to send this medicine list. As soon as the pharmacy gets your request, they'll respond with a quote!</h5>
                    </div>
                ) : (
                    <div>
                        <h5 className="text-center font-weight-bold pt-2 pb-2">Now Let's find a Nearby Diagnostic Lab to Send This Lab Test Request!</h5>
                        <h5 className="text-center font-weight-light pt-2 pb-2">Find one or more diagnostic labs nearest to your locality to send this lab test list. As soon as the lab gets your request, they'll respond with a quote!</h5>
                    </div>
                )
                }


                <div className="w-100 d-flex pt-5 pb-5 justify-content-center">
                    <button className="btn btn-primary bg-theme" onClick={redirectToPatientDashboard}>Find nearby {sendQuoteTo[entity]}</button>
                </div>
            </div>
        </div>
    )
}