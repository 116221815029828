import React, { useState } from 'react';
import { register } from 'api/auth';
import { useDispatch } from 'react-redux';
import { toastError } from 'redux/Toast';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PasswordInput from './PasswordInput';

const passwordRegex = new RegExp(/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&.])/);

const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: ''
}


export const Register = (
    {
        goToLogin,
        goToOtpVerification
    }
) => {
    const dispatch = useDispatch();
    // const [firstName, setFirstName] = useState('');
    // const [lastName, setLastName] = useState('');
    // const [email, setEmail] = useState('');
    // const [password, setPassword] = useState('');


    const RegistrationSchema = Yup.object().shape({
        password: Yup.string()
            .min(8, 'Must be 8 characters or more.')
            .max(20, 'Minimum length of 20 characters.')
            .matches(passwordRegex, "Include at least  one upper case, lower case, numbers and symbols.")
            .required('The password field is required.'),
    });

    // const registerUser = async () => {
    //     try {
    //         await register({
    //             first_name: firstName,
    //             last_name: lastName,
    //             email,
    //             password
    //         });
    //         localStorage.registered_email = email;
    //         goToOtpVerification();
    //     } catch (e) {
    //         console.log(e);
    //         if (e.errors) {
    //             Object.entries(e.errors).forEach(error => {
    //                 setTimeout(() => {
    //                     dispatch(toastError(error[1][0]));
    //                 }, 100);
    //             });
    //         } else {
    //             dispatch(toastError(e.message))
    //         }
    //     }
    // }

    const onSubmit = async (values) => {
        try {
            await register({
                first_name: values.firstName,
                last_name: values.lastName,
                email: values.email,
                password: values.password
            });
            localStorage.registered_email = values.email;
            goToOtpVerification();
        } catch (e) {
            console.log(e);
            if (e.errors) {
                Object.entries(e.errors).forEach(error => {
                    setTimeout(() => {
                        dispatch(toastError(error[1][0]));
                    }, 100);
                });
            } else {
                dispatch(toastError(e.message))
            }
        }
    }


    const Formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema: RegistrationSchema
    });

    return (
        <div className="w-100">
            <form onSubmit={Formik.handleSubmit}>
                <div className="d-flex">
                    <input
                        type="text"
                        id='firstName'
                        name='firstName'
                        className="form-control w-50 pt-4 pb-4 mb-2 mr-1"
                        value={Formik.values.firstName}
                        onChange={Formik.handleChange}
                        onBlur={Formik.handleBlur}
                        placeholder="First Name"
                        autoComplete="new-password"
                    />
                    <input
                        type="text"
                        id='lastName'
                        name='lastName'
                        className="form-control w-50 pt-4 pb-4 mb-2 ml-1"
                        value={Formik.values.lastName}
                        onChange={Formik.handleChange}
                        onBlur={Formik.handleBlur}
                        placeholder="Last Name"
                        autoComplete="new-password"
                    />
                </div>
                <input
                    type="text"
                    id='email'
                    name='email'
                    className="form-control pt-4 pb-4 mb-2"
                    value={Formik.values.email}
                    onChange={Formik.handleChange}
                    onBlur={Formik.handleBlur}
                    placeholder="Email"
                    autoComplete="new-password"
                />
                <PasswordInput
                    value={Formik.values.password}

                    name="password"
                    onChange={Formik.handleChange}
                    onBlur={Formik.handleBlur}
                    hasError={Formik.touched.password && Formik.errors.password}
                    feedback={Formik.errors.password || 'field is required'}
                />
                <div className="d-flex align-items-center">
                    <button
                        className="btn btn-primary bg-theme f-14 mr-3"
                        type='submit'
                        disabled={
                            !Formik.values.firstName ||
                            !Formik.values.lastName ||
                            !Formik.values.email ||
                            !Formik.values.password ||
                            Formik.errors.password
                        }
                    >Register</button>
                    <span
                        className="cursor-pointer f-13"
                        onClick={goToLogin}
                    ><u>Already have an account?</u> </span>
                </div>
            </form>
        </div>
    )
}