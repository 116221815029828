import { http } from 'api/http';

export const MEDICINE = 'medicine';
export const GET_MEDICINES = "search/medicine";

export const getMedicines = (payload = {}) => {
    return http.get(GET_MEDICINES, {
        params: payload
    });
}

export const viewMedicine = (id) => {
    return http.get(MEDICINE + "/" + id);
}