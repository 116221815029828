import React from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { hide as hideCategory } from 'redux/Category';
import CategoryImage from 'images/category.svg';
import './CategoryCard.scss';

export const CategoryCard = ({category,image}) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const goToProducts = () => {
        dispatch(hideCategory());
        history.push("/medicines/search?title=" + category + "&category=" + category);
    }
    
    return (
        <div 
            className='w-100 h-100 category-card d-flex flex-column align-items-center justify-content-between p-4 cursor-pointer'
            onClick={() => goToProducts()}
        >
            <img src={image || CategoryImage} alt="category" className="w-50 mb-3" />
            <p className="text-center">{category}</p>
        </div>
    );
}