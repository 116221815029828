import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GET_MEDICINES, getMedicines } from 'api/medicine';

export const fetchMedicines = createAsyncThunk(GET_MEDICINES, async (payload) => {
    const response = await getMedicines(payload);
    return {
        rx_dispatch_type: payload.rx_dispatch_type,
        search_query_string: payload.search_query_string,
        response
    };
});

export const FetchMedicinesSlice = createSlice({
    name: 'fetchMedicinesSlice',
    initialState: {
        home_medicine_preview: {
            loading: true,
            current_page: 1,
            total_page: 1,
            data: []
        },
        home_medicine_random_preview: {
            loading: true,
            current_page: 1,
            total_page: 1,
            data: []
        },
        search_medicines : {
            loading: true,
            current_page: 1,
            total_page: 1,
            data: []
        },
        default: {
            loading: true,
            current_page: 1,
            total_page: 1,
            data: []
        }
    },
    reducers: {
        setLoading: (state,action) => {
            state[action.payload.rx_dispatch_type].loading = action.payload.loading;
            return state;
        },
        setCurrentPage: (state,action) => {
            state[action.payload.rx_dispatch_type].current_page = action.payload.current_page;
            return state;
        },
        resetState: (state,action) => ({
            ...state,
            [action.payload.rx_dispatch_type]: {
                loading: false,
                current_page: 1,
                total_page: 1,
                data: []
            }
        })
    },
    extraReducers: {
        [fetchMedicines.pending]: (state, action) => {
            state[action.meta.arg.rx_dispatch_type].loading = true;
            state[action.meta.arg.rx_dispatch_type].search_query_string = action.meta.arg.search_query_string;
        },
        [fetchMedicines.fulfilled]: (state, action) => {
            state[action.payload.rx_dispatch_type].loading = false
            state[action.payload.rx_dispatch_type].current_page = action.payload.response.current_page;
            state[action.payload.rx_dispatch_type].total_page = action.payload.response.last_page;
            state[action.payload.rx_dispatch_type].data = [
                ...state[action.payload.rx_dispatch_type].data,
                ...action.payload.response.data
            ];
        },
        [fetchMedicines.rejected]: (state, action) => {
            state[action.meta.arg.rx_dispatch_type].loading = false;
        }
    }
});

export const { setLoading, setCurrentPage, resetState } = FetchMedicinesSlice.actions;

export const FetchMedicinesReducer = FetchMedicinesSlice.reducer;