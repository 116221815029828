import React from 'react';
import './MainContainer.scss';
import { useSelector } from 'react-redux';

export const MainContainer = (props) => {
    const height = useSelector(state => state.headerHeight);
    
    return (
        <div 
            className="w-100 pb-4 pr-lg-5 pl-lg-5 pr-md-4 pl-md-4 pr-sm-3 pl-sm-3 pl-2 pr-2 main-container" 
            style={{ 
                paddingTop: height + "px" 
            }}>
            {props.children}
        </div>
    );
}