import React, { useState } from 'react';
import { verifyOtp, resendEmailOtp } from 'api/auth';
import { toastError } from 'redux/Toast';
import { useDispatch } from 'react-redux';

export const VerifyOtp = ({
    goToLogin,
    updateLoggedInUserAndRedirect
}) => {
    const [otp, setOtp] = useState('');
    const dispatch = useDispatch();

    const verifyEmailOtp = async () => {
        try {
            const response = await verifyOtp({
                email: localStorage.registered_email,
                email_otp: otp
            });
            localStorage.removeItem("registered_email");
            updateLoggedInUserAndRedirect(response);
        } catch (e) {
            // console.log(e);
            dispatch(toastError(e.message));
        }
    }

    const resendEmailOtpOnWeb = async () => {
        try {
            const response = await resendEmailOtp({
                email: localStorage.registered_email
            });
        } catch (e) {
            // console.log(e);
            dispatch(toastError(e.message));
        }
    }

    return (
        <div className="w-100">
            <input
                type="number"
                className="form-control pt-4 pb-4 mb-3"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                placeholder="Enter OTP"
                autoComplete="new-password"
            />
            <div className="d-flex align-items-center">
                <button
                    className="btn btn-primary bg-theme f-14 mr-3"
                    onClick={verifyEmailOtp}
                >Verify OTP</button>
                <button
                    className="btn btn-primary bg-theme f-14 mr-3"
                    onClick={resendEmailOtpOnWeb}
                >Resend OTP</button>
                <span
                    className="cursor-pointer f-13"
                    onClick={goToLogin}
                ><u>Already have an account?</u> </span>
            </div>
        </div>
    )
}