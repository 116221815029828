import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ProductCard, ProductDetCard } from 'components/Product';
import { useSelector, useDispatch } from 'react-redux';
import { fetchLabTest } from 'redux/Test';
import { SectionContainer } from 'components/Containers';
import { Loader } from 'components/Core';
import { useHistory } from "react-router-dom";

export const Test = () => {
    const history = useHistory();
    const labTest = useSelector(state => state.viewLabTest.data);
    const loading = useSelector(state => state.viewLabTest.loading);
    const { testId } = useParams();
    const loadedLabTestId = useSelector(state => state.viewLabTest.lab_test_id);
    const dispatch = useDispatch();

    // useEffect(() => {
    //     if (loadedMedicineId !== parseInt(20)){
    //         dispatch(fetchLabTest(20));
    //     }
    // }, [medicineId, loadedMedicineId,dispatch]);

    useEffect(() => {
        let labId = parseInt(testId) || 0
        if(labId===0){
            history.push("/");
            return
        }

        if (loadedLabTestId !== parseInt(testId)){
            dispatch(fetchLabTest(testId));
        }
    }, [testId, loadedLabTestId,dispatch]);

    return (
        <SectionContainer>
            {
                loading && <Loader />
            }
            {
                !loading && (
                    <ProductDetCard
                        viewType="detailed"
                        productId={labTest.id}
                        name={labTest.name}
                        manufacturer={labTest.manufacturer}
                        description={labTest.short_disc}
                        image={labTest.image}
                        price={labTest.price}
                        rxRequired={labTest.rx_required}
                    />
                )
            }
        </SectionContainer>
    );
}