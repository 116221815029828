import axios from "axios";

let http = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

http.interceptors.request.use(
    function (config) {
        const token = localStorage.access_token ? localStorage.access_token : "";
        config.headers.Authorization = `Bearer ${token}`;
        config.headers.common["X-Requested-With"] = "XMLHttpRequest";
        config.headers.post["Content-Type"] = "application/json";
        return config;
    },
    function (err) {
        return Promise.reject(err);
    }
);

http.interceptors.response.use(
    response => {
        return response.data;
    },
    error => {
        return Promise.reject(error.response.data);
    }
);

export { http };