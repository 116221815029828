import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import parse from "html-react-parser";
import { AddShoppingCart, RemoveShoppingCart } from "@material-ui/icons";
import { CircularProgress } from "@material-ui/core";
import Carousel from "react-elastic-carousel";
import { Entity } from "context";
import { updateCartProduct, deleteCartProduct } from "redux/Cart";
import { toastSuccess } from "redux/Toast";
import CategoryImage from "images/category.svg";
import "./ProductDetCard.css";

export const ProductDetCard = ({
  productId,
  description,
  image,
  name,
  manufacturer,
  price,
  rxRequired,
  viewType = "grid",
}) => {
  const dispatch = useDispatch();
  const entity = useContext(Entity);
  const cartProduct = useSelector((state) =>
    state.cart[entity].products.find(
      (p) => p[entity.slice(0, -1)].id === productId
    )
  );
  const cartId = useSelector((state) => state.cart[entity].cart_id);
  const [count, setCount] = useState(1);
  const [initialLoad, setInitialLoad] = useState(false);
  const [updatingCart, setUpdatingCart] = useState(false);
  const history = useHistory();
  const [gallery, setGallery] = useState([]);

  const addItemToCart = (newProduct = false) => {
    if (cartId && (!cartProduct || cartProduct.quantity !== count)) {
      setUpdatingCart(true);
      dispatch(
        updateCartProduct({
          rx_dispatch_type: entity,
          cart_id: cartId,
          item_id: productId,
          quantity: count,
        })
      )
        .then(unwrapResult)
        .then(() => {
          if (newProduct) {
            dispatch(toastSuccess("Product added to cart successfully"));
          }
          setUpdatingCart(false);
        })
        .catch(() => {
          setUpdatingCart(false);
        });
    }
  };

  const removeItemFromCart = () => {
    if (cartId && cartProduct.id) {
      setUpdatingCart(true);
      dispatch(
        deleteCartProduct({
          rx_dispatch_type: entity,
          cart_id: cartId,
          cart_item_id: cartProduct.id,
        })
      )
        .then(unwrapResult)
        .then(() => {
          dispatch(toastSuccess("Product removed from cart successfully"));
          setUpdatingCart(false);
        })
        .catch(() => {
          setUpdatingCart(false);
        });
    }
  };

  useEffect(() => {
    if (cartProduct) {
      setCount(cartProduct.quantity);
    }
  }, [cartProduct]);

  useEffect(() => {
    if (initialLoad && cartProduct && cartProduct.quantity !== count) {
      addItemToCart();
    }
    setInitialLoad(true);
  }, [count]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setGallery(Array.isArray(image) ? image : [image].filter((i) => i));
  }, [image]);

  const styles = {
    detailed: {
      mainContainer:
        "col-12 p-3 d-flex flex-wrap position-relative product-card-container",
      rxPill:
        "bg-danger text-white float-right f-10 pr-3 pl-3 pt-1 pb-1 rx-pill",
      imgContainer:
        "col-12 col-sm-6 d-flex flex-column mb-4 mb-sm-0 p-0 p-sm-2",
      img: "w-100",
      content: "w-100",
      name: "font-weight-bold f-25 mb-2",
      manufacturer: "text-secondary f-15 mb-3",
      price: "f-25 font-weight-bold",
      actionContainer: "d-flex flex-column col-12 col-sm-6 add-to-cart",
      actions: "d-flex w-100 pt-3 pb-3",
      qtyContainer: "d-flex align-items-center mr-2",
      addToCartContainer: "d-flex align-items-center",
    },
    btnStyle: {
      border: "0",
      color: "#fff",
      backgroundColor: "#fcb716",
      boxShadow: "0 2px 4px 0 rgba(0,0,0, 0.2)",
      fontWeight: "bold",
    },
  };

  return (
    <div className="jss7">
      <div className="jss9" style={{ borderRadius: "50px" }}>
        <div className="jss15">
          <div className="jss17">
            <h1>{name}</h1>
            <div className="jss57">
              <div className="jss62">
                <div>MRP ₹ {price}</div>
              </div>
            </div>
          </div>
          <div className="jss54 jss72">
            {/* <button className="jss250 jss251" style={{ border: "0" }}>
              <span className="MuiButton-label">Add to Cart</span>
              <span class="MuiTouchRipple-root"></span>
            </button> */}

            {
              <button
                type="button"
                className={!cartProduct ? "btn" : "d-none"}
                style={styles.btnStyle}
                onClick={() => addItemToCart(true)}
                title="Add to cart"
              >
                {viewType === "detailed" && (
                  <span className="mr-2">Add to cart</span>
                )}
                <CircularProgress
                  size={14}
                  className={
                    updatingCart ? "text-white align-middle" : "d-none"
                  }
                />
                <AddShoppingCart
                  className={!updatingCart ? "icon" : "d-none"}
                />
              </button>
            }
            {cartProduct && (
              <button
                type="button"
                className={cartProduct ? "btn" : "d-none"}
                style={styles.btnStyle}
                onClick={removeItemFromCart}
                title="Remove from cart"
              >
                {viewType === "detailed" && (
                  <span className="mr-2">Remove</span>
                )}
                <CircularProgress
                  size={14}
                  className={
                    updatingCart ? "text-white align-middle" : "d-none"
                  }
                />
                <RemoveShoppingCart
                  className={!updatingCart ? "icon" : "d-none"}
                />
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="jss29">
        <div className="jss66">
          {description && (
            <div className="w-100 pt-4 pb-4 product-description">
              <h1 className="mb-3">About this item: </h1>
              {parse(description)}
            </div>
          )}
        </div>
      </div>
    </div>
    // <div
    //     className={styles[viewType].mainContainer}
    // >
    //     {
    //         rxRequired === 1 &&
    //         <div className="position-absolute w-100 rx-container">
    //             <span className={styles[viewType].rxPill}>Rx Required</span>
    //         </div>
    //     }
    //     <div
    //         className={styles[viewType].imgContainer}
    //         onClick={() => {
    //             if (entity === "medicines" && !(viewType === "detailed") && productId) {
    //                 history.push("/" + entity + "/" + productId);
    //             }
    //         }}
    //     >
    //         {
    //             viewType !== "detailed" &&
    //             <img src={gallery.length > 0 ? gallery[0] : CategoryImage} alt={name} className={styles[viewType].img} />
    //         }
    //         {
    //             viewType === "detailed" &&
    //             <Carousel
    //                 itemsToShow={1}
    //                 pagination={false}
    //             >
    //                 {
    //                     gallery.length > 0 ?
    //                     gallery.map((i, k) => <img src={i} alt={name} className={styles[viewType].img} key={k} />) :
    //                     <img src={CategoryImage} alt={name} className={styles[viewType].img} />
    //                 }
    //             </Carousel>
    //         }
    //     </div>
    //     <div className={styles[viewType].actionContainer}>
    //         <div
    //             className={styles[viewType].content}
    //             onClick={() => {
    //                 if (entity === "medicines" && !(viewType === "detailed") && productId) {
    //                     history.push("/" + entity + "/" + productId);
    //                 }
    //             }}
    //         >
    //             <h6 className={styles[viewType].name}>{name}</h6>
    //             {
    //                 manufacturer &&
    //                 <p className={styles[viewType].manufacturer}><i>Mfr: {manufacturer}</i></p>
    //             }
    //             <p className={styles[viewType].price}>₹ {price}</p>
    //         </div>
    //         <div className={styles[viewType].actions}>
    //             {entity === "medicines" ? <div className={styles[viewType].qtyContainer}>
    //                 <button type="button" className="inc-dec pl-2 pr-2 pt-1 pb-1" onClick={() => setCount(c => c - 1 < 1 ? 1 : c - 1)}> - </button>
    //                 <span className="p-2">{count}</span>
    //                 <button type="button" className="inc-dec pl-2 pr-2 pt-1 pb-1" onClick={() => setCount(c => c + 1)}> + </button>
    //             </div>: <div>&nbsp;</div>}
    //             <div className={styles[viewType].addToCartContainer}>
    //                 {
    //                     <button type="button" className={!cartProduct ? 'btn' : 'd-none'} onClick={() => addItemToCart(true)} title="Add to cart">
    //                         {
    //                             viewType === "detailed" && <span className="mr-2">Add to cart</span>
    //                         }
    //                         <CircularProgress size={14} className={updatingCart ? "text-white align-middle" : "d-none"} />
    //                         <AddShoppingCart className={!updatingCart ? "icon" : "d-none"} />
    //                     </button>
    //                 }
    //                 {
    //                     cartProduct &&
    //                     <button type="button" className={cartProduct ? 'btn' : 'd-none'} onClick={removeItemFromCart} title="Remove from cart">
    //                         {
    //                             viewType === "detailed" && <span className="mr-2">Remove</span>
    //                         }
    //                         <CircularProgress size={14} className={updatingCart ? "text-white align-middle" : "d-none"} />
    //                         <RemoveShoppingCart className={!updatingCart ? "icon" : "d-none"} />
    //                     </button>
    //                 }
    //             </div>
    //         </div>
    //         {
    //             description && (
    //                 <div className="w-100 pt-4 pb-4 product-description">
    //                     <h1 className="mb-3">About this item: </h1>
    //                     {parse(description)}
    //                 </div>
    //             )
    //         }
    //     </div>
    // </div>
  );
};
