import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ProductCard } from 'components/Product';
import { useSelector, useDispatch } from 'react-redux';
import { fetchMedicine } from 'redux/Medicine';
import { SectionContainer } from 'components/Containers';
import { Loader } from 'components/Core';
import { useHistory } from "react-router-dom";

export const Medicine = () => {
    const history = useHistory();
    const medicine = useSelector(state => state.viewMedicine.data);
    const loading = useSelector(state => state.viewMedicine.loading);
    const { medicineId } = useParams();
    const loadedMedicineId = useSelector(state => state.viewMedicine.medicine_id);
    const dispatch = useDispatch();

    useEffect(() => {
        let medId=parseInt(medicineId) || 0
        if(medId===0){
            history.push("/");
            return
        }

        if (loadedMedicineId !== parseInt(medicineId)){
            dispatch(fetchMedicine(medicineId));
        }
    }, [medicineId, loadedMedicineId,dispatch]);

    return (
        <SectionContainer>
            {
                loading && <Loader />
            }
            {
                !loading && (
                    <ProductCard
                        viewType="detailed"
                        productId={medicine.id}
                        name={medicine.name}
                        manufacturer={medicine.manufacturer}
                        description={medicine.short_desc}
                        image={medicine.image_url}
                        price={medicine.price_per_strip}
                        rxRequired={medicine.rx_required}
                    />
                )
            }
        </SectionContainer>
    );
}