import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Loader } from 'components/Core';
import { SectionContainer } from 'components/Containers';
import { ProductCard } from 'components/Product';
import { fetchMedicines, setLoading, setCurrentPage, resetState } from 'redux/Medicine';
import { useQuery, useWindowSize } from 'helpers';

export const Medicines = () => {
    const query = useQuery();
    const queryString = query.toString();
    const dispatch = useDispatch();
    const category = query.get("category");
    const name = query.get("name");
    const medicines = useSelector(state => state.medicines.default.data);
    const loading = useSelector(state => state.medicines.default.loading);
    const currentPage = useSelector(state => state.medicines.default.current_page);
    const totalPage = useSelector(state => state.medicines.default.total_page);
    const dataQueryString = useSelector(state => state.medicines.default.search_query_string);
    const [viewType,setViewType] = useState("grid");
    const windowSize = useWindowSize();
    
    useEffect(() => {
        if(windowSize.width < 576){
            setViewType("list");
        }else {
            setViewType("grid");
        }
    }, [windowSize])

    useEffect(() => {
        if (loading){
            dispatch(fetchMedicines({
                page: currentPage,
                rx_dispatch_type: 'default',
                category: category,
                search_query_string: queryString,
                name
            }));
        }
    }, [category, name, dispatch, loading, queryString, currentPage]);

    useEffect(() => {
        if(!loading && queryString !== dataQueryString){
            dispatch(resetState({
                rx_dispatch_type: 'default'
            }));
            dispatch(setLoading({
                rx_dispatch_type: 'default',
                loading: true
            }));
        }
    }, [loading, queryString, dataQueryString, dispatch])

    return (
        <SectionContainer title={query.get("title") || "Medicines"}>
            {
                medicines.length > 0 &&
                    <div className="w-100 d-flex flex-wrap">
                        {
                            medicines.map((medicine, i) => (
                                <div className="col-md-3 col-sm-4 p-1" key={i}>
                                    <ProductCard
                                        productId={medicine.id}
                                        name={medicine.name}
                                        manufacturer={medicine.manufacturer}
                                        image={medicine.image_url}
                                        price={medicine.price_per_strip}
                                        rxRequired={medicine.rx_required}
                                        viewType={viewType}
                                    />
                                </div>
                            ))
                        }
                    </div>
            }
            { loading && <Loader />}
            {
                !loading && currentPage < totalPage &&
                <div className="w-100 d-flex justify-content-center pt-5 pb-3">
                    <button
                        className="btn btn-primary bg-theme"
                        onClick={() => {
                            dispatch(setCurrentPage({
                                rx_dispatch_type: 'default',
                                current_page: currentPage + 1
                            }))
                            dispatch(setLoading({
                                rx_dispatch_type: 'default',
                                loading: true
                            }))
                        }}>
                        Load More</button>
                </div>
            }
            {
                !loading && medicines.length === 0 &&
                <p className="text-center pt-5 pb-5">No medicines found for your search criteria</p>
            }
        </SectionContainer>
    );
}