import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GET_LAB_TEST, viewLabTest } from 'api/test';

export const fetchLabTest = createAsyncThunk(GET_LAB_TEST + "/{id}", async (payload) => {
    const response = await viewLabTest(payload);
    return response;
});

export const FetchLabTestSlice = createSlice({
    name: 'fetchLabTestSlice',
    initialState: {
        loading: true,
        lab_test_id: null,
        data: {}
    },
    reducers: {},
    extraReducers: {
        [fetchLabTest.pending]: (state,action) => {
            state.loading = true;
            state.lab_test_id = parseInt(action.meta.arg);
        },
        [fetchLabTest.fulfilled]: (state, action) => {
            state.data = action.payload;
            state.data.short_desc = !state.data.short_desc || state.data.short_desc === "null" ? "No description available" : state.data.short_desc;
            state.loading = false;
        },
        [fetchLabTest.rejected]: (state) => {
            state.loading = false;
            state.data = [];
        }
    }
});

export const ViewLabTestReducer = FetchLabTestSlice.reducer;