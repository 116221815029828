import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    success: "",
    error: ""
};

export const ToastMessageSlice = createSlice({
    name: 'toast',
    initialState: initialState,
    reducers: {
        toastSuccess: (state,action) => ({
            ...state,
            success: action.payload
        }),
        toastError: (state, action) => ({
            ...state,
            error: action.payload
        }),
        resetToastMessage: () => initialState
    }
});

export const { toastSuccess, toastError, resetToastMessage } = ToastMessageSlice.actions;

export const ToastMessageReducer = ToastMessageSlice.reducer;