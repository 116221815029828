import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Entity } from 'context';

export const UploadPrescription = ({
    goToLoginAndRegister,
    goToGenerateQuote,
    setPrescription,
    prescription = null
}) => {
    const [file, setFile] = useState(prescription);
    const [showPrescription,setShowPrescription] = useState(true);
    const entity = useContext(Entity);
    const cart = useSelector(state => state.cart);
    useEffect(() => {
        if(entity === 'medicines'){
            setShowPrescription(!!cart && cart[entity].products.find(product => product.medicine.rx_required === 1));
        }
    },[cart,entity]);

    const initializePrescription = (file) => {
        setFile(file);
        setPrescription(file);
    }

    return (
        <>
            {
                showPrescription &&
                <div className="w-100 mb-3 pt-2 pb-2">
                    <div className="w-100 bg-light-grey p-1">
                        <label className="btn btn-secondary m-0">
                            <input type="file" className="d-none" onChange={(e) => initializePrescription(e.target.files[0])} accept=".jpg,.jpeg,.png,.pdf" />
                        Choose file...
                    </label>
                        <span className="f-14 text-secondary pl-3 pr-3">
                            {
                                file && file.name ?
                                    file.name :
                                    'Can be pdf, jpg, jpeg or png'
                            }
                        </span>
                    </div>
                </div>
            }
            {
                !showPrescription &&
                <p className="mb-3 mt-3">You don't have any Rx medicines in your cart</p>
            }
            <div className="w-100">
                <button className="btn btn-light mr-2" onClick={goToLoginAndRegister}>Previous</button>
                <button 
                    className="btn btn-primary bg-theme" 
                    onClick={goToGenerateQuote} 
                    disabled={showPrescription && !file}
                >Next</button>
            </div>
        </>
    );
}