import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Login } from './Login';
import { Register } from './Register';
import { VerifyOtp } from './VerifyOtp';
import { setAccessToken, setRefreshToken, fetchProfile } from 'redux/Me';

export const LoginAndRegister = ({ 
    goToUploadPrescription
}) => {
    const dispatch = useDispatch();
    const [showLoginTab,setShowLoginTab] = useState(true);
    const [showRegisterTab,setShowRegistertab] = useState(false);
    const [registerTabStep, setRegisterTabStep] = useState(1);

    const switchTab = (type = "login") => {
        if(type === "login"){
            setShowLoginTab(true);
            setShowRegistertab(false);
            setRegisterTabStep(1);
        }else if(type === "register"){
            setShowLoginTab(false);
            setShowRegistertab(true);
            setRegisterTabStep(1);
        }
    }

    const redirectToVerifyOtp = () => {
        switchTab('register');
        setRegisterTabStep(2);
    }

    const updateLoggedInUserAndRedirect = (response) => {
        if (response.roles.includes("patient")) {
            dispatch(setAccessToken(response.access_token));
            dispatch(setRefreshToken(response.refresh_token));
            dispatch(fetchProfile());
            goToUploadPrescription();
        }
    }

    return (
        <div className="w-100 pt-3 pb-3">
            {
                showLoginTab &&
                <Login 
                    updateLoggedInUserAndRedirect={updateLoggedInUserAndRedirect}
                    redirectToPrescription={goToUploadPrescription}
                    goToRegister={() => switchTab('register')}
                    goToVerifyOtp={redirectToVerifyOtp}
                />
            }
            {
                showRegisterTab && 
                <div className="w-100">
                    {
                        registerTabStep === 1 && 
                        <Register 
                            goToOtpVerification={() => setRegisterTabStep(2)}
                            goToLogin={() => switchTab('login')}
                        />
                    }
                    {
                        registerTabStep === 2 &&
                        <VerifyOtp
                            updateLoggedInUserAndRedirect={updateLoggedInUserAndRedirect}
                            goToLogin={() => switchTab('login')}
                        />
                    }
                </div>
            }
        </div>
    );
}