import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { generateQuote } from 'api/checkout';
import { resetCart } from 'redux/Cart';
import { toastError } from 'redux/Toast';
import { Entity } from 'context';

export const GenerateQuote = ({
    goToUploadPrescription,
    prescription
}) => {
    console.log('prescription', prescription);
    const dispatch = useDispatch();
    const history = useHistory();
    const entity = useContext(Entity);
    const cart = useSelector(state => state.cart[entity]);
    const sendQuoteTo = {
        medicines: "pharmacies",
        tests: "laboratories"
    }

    const generateQuoteFromCartItems = async () => {
        try{
            const form = new FormData();
            form.append("cart_id",cart.cart_id);
            if (prescription) {
                form.append("prescription_file",prescription);
            }
            const response = await generateQuote(form, {
                headers:{
                    'content-type': 'multipart/form-data'
                }
            });
            dispatch(resetCart({
                rx_dispatch_type: entity
            }));
            history.push("/" + entity + "/checkout/" + response.prescription_id + "/success")
        }catch(e){
            console.log(e);
            dispatch(toastError(e.message));
        }
    }

    return (
        <div className="w-100">
            <div className="w-100 pt-3 pb-4">
                <p className="font-weight-bold mb-2">Instructions</p>
                <p className="f-14 mb-1">1) A Quote will be generated for the items you have added in your cart.</p>
                <p className="f-14 mb-1">2) You will get an option to search nearby { sendQuoteTo[entity] } registered in our platform.</p>
                <p className="f-14 mb-1">3) Select the { sendQuoteTo[entity] } and click <b>Send Quote</b></p>
                <p className="f-14 mb-1">4) The {sendQuoteTo[entity] } will get back to you with the updated price.</p>
            </div>
            <div className="w-100">
                <button className="btn btn-light mr-2" onClick={goToUploadPrescription}>Previous</button>
                <button className="btn btn-primary bg-theme" onClick={generateQuoteFromCartItems}>Generate Quote</button>
            </div>
        </div>
    );
}