import { createSlice } from '@reduxjs/toolkit';

export const HeaderHeightSlice = createSlice({
    name: 'headerHeight',
    initialState: 0,
    reducers: {
        set: (value, action) => action.payload
    }
});

export const { set } = HeaderHeightSlice.actions;

export const HeaderHeightReducer = HeaderHeightSlice.reducer;