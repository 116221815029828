import React from 'react';
import { ProductCard } from 'components/Product';
import Carousel from 'react-elastic-carousel';

export const ProductCarousel = ({ products = [], type = "medicine" }) => {

const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 500, itemsToShow: 2},
        { width: 600, itemsToShow: 3},
        { width: 800, itemsToShow: 4}
      ];
      
    return (
        <Carousel
            breakPoints={breakPoints}
            pagination={false}
        >
            {
                products.map((product, i) => (
                    <div key={i} className="mr-2 ml-2">
                        <ProductCard
                            productId={product.id}
                            name={product.name}
                            manufacturer={product.manufacturer}
                            image={product.image_url || product.image}
                            price={product.price_per_strip || product.price}
                            rxRequired={product.rx_required}
                        />
                    </div>
                ))
            }
        </Carousel>
    );
}