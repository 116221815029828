import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { MEDICINE, viewMedicine } from 'api/medicine';

export const fetchMedicine = createAsyncThunk(MEDICINE + "/{id}", async (payload) => {
    const response = await viewMedicine(payload);
    return response;
});

export const FetchMedicineSlice = createSlice({
    name: 'fetchMedicineSlice',
    initialState: {
        loading: true,
        medicine_id: null,
        data: {}
    },
    reducers: {},
    extraReducers: {
        [fetchMedicine.pending]: (state,action) => {
            state.loading = true;
            state.medicine_id = parseInt(action.meta.arg);
        },
        [fetchMedicine.fulfilled]: (state, action) => {
            state.data = action.payload;
            state.data.short_desc = !state.data.short_desc || state.data.short_desc === "null" ? "No description available" : state.data.short_desc;
            state.loading = false;
        },
        [fetchMedicine.rejected]: (state) => {
            state.loading = false;
            state.data = [];
        }
    }
});

export const ViewMedicineReducer = FetchMedicineSlice.reducer;