import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GET_CATEGORIES, getCategories } from 'api/category';

export const fetchCategories = createAsyncThunk(GET_CATEGORIES, async (payload) => {
    const response = await getCategories(payload);
    return response;
});

export const FetchCategoriesSlice = createSlice({
    name: 'fetchCategoriesSlice',
    initialState: {
        loading: true,
        data: []
    },
    reducers: {},
    extraReducers: {
        [fetchCategories.pending]: (state) => {
            state.loading = true;
        },
        [fetchCategories.fulfilled]: (state,action) => {
            state.loading = false;
            state.data = action.payload;
        },
        [fetchCategories.rejected]: (state) => {
            state.loading = false;
            state.data = [];
        }
    }
});

export const FetchCategoriesReducer = FetchCategoriesSlice.reducer;