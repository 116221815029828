import { createSlice } from '@reduxjs/toolkit';

export const ShowCategoryPopupSlice = createSlice({
    name: 'showCategoryPopup',
    initialState: false,
    reducers: {
        show: () => true,
        hide: () => false
    }
});

export const { show, hide } = ShowCategoryPopupSlice.actions;

export const ShowCategoryPopupReducer  = ShowCategoryPopupSlice.reducer;