import { configureStore } from '@reduxjs/toolkit'
import { CartReducer } from 'redux/Cart';
import { ShowCategoryPopupReducer, FetchCategoriesReducer } from 'redux/Category';
import { HeaderHeightReducer } from 'redux/Layout';
import { MeReducer } from 'redux/Me';
import { FetchMedicinesReducer, ViewMedicineReducer } from 'redux/Medicine';
import { FetchTestsReducer, ViewLabTestReducer } from 'redux/Test';
import { ToastMessageReducer } from 'redux/Toast';

export default configureStore({
    reducer: {
        cart: CartReducer,
        categories: FetchCategoriesReducer,
        headerHeight: HeaderHeightReducer,
        me: MeReducer,
        medicines: FetchMedicinesReducer,
        viewMedicine: ViewMedicineReducer,
        showCategoryPopup: ShowCategoryPopupReducer,
        tests: FetchTestsReducer,
        viewLabTest:ViewLabTestReducer,
        toast: ToastMessageReducer
    }
});