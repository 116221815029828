import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import parse from 'html-react-parser';
import { AddShoppingCart, RemoveShoppingCart } from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';
import Carousel from 'react-elastic-carousel';
import { Entity } from 'context';
import { updateCartProduct, deleteCartProduct } from 'redux/Cart';
import { toastSuccess } from 'redux/Toast';
import CategoryImage from 'images/category.svg';
import './ProductTestCard.scss';

export const ProductTestCard = ({
    productId,
    description,
    image,
    name,
    manufacturer,
    price,
    rxRequired,
    viewType = "grid",
}) => {
    const dispatch = useDispatch();
    const entity = useContext(Entity);
    const cartProduct = useSelector(state => state.cart[entity].products.find(p => p[entity.slice(0, -1)].id === productId));
    const cartId = useSelector(state => state.cart[entity].cart_id);
    const [count, setCount] = useState(1);
    const [initialLoad, setInitialLoad] = useState(false);
    const [updatingCart, setUpdatingCart] = useState(false);
    const history = useHistory();
    const [gallery, setGallery] = useState([]);
    const [desc, setDesc] = useState([]);
    // const dummyDesc = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium saepe perferendis placeat perspiciatis reiciendis fuga sint, quod, est itaque culpa id quaerat unde, minima voluptatum tempore odit harum sunt? Tenetur, saepe! Odit mollitia, eos aliquid illo dolores dignissimos neque quos ipsa corporis voluptas culpa necessitatibus. Ratione commodi voluptas consequuntur nulla omnis magnam quidem placeat beatae obcaecati officia animi numquam, iusto doloremque repellendus. Minima enim laboriosam reiciendis dignissimos eos fugit vel, ab quas non repellat voluptatum! Doloremque eligendi nobis hic porro nemo incidunt? Asperiores ab eius accusamus dolorum perspiciatis vitae ratione deleniti, impedit provident inventore modi officiis quo rerum libero eveniet."

    const addItemToCart = (newProduct = false) => {
        // console.log("Add Item to Cart", cartId)
        if (cartId && (!cartProduct || cartProduct.quantity !== count)) {
            console.log("In the Product Card");
            setUpdatingCart(true);
            dispatch(updateCartProduct({
                rx_dispatch_type: entity,
                cart_id: cartId,
                item_id: productId,
                quantity: count
            }))
                .then(unwrapResult)
                .then(() => {
                    if (newProduct) {
                        dispatch(toastSuccess("Product added to cart successfully"));
                    }
                    setUpdatingCart(false);
                }).catch(() => {
                    setUpdatingCart(false);
                });
        }
    }

    const removeItemFromCart = () => {
        if (cartId && cartProduct.id) {
            setUpdatingCart(true);
            dispatch(deleteCartProduct({
                rx_dispatch_type: entity,
                cart_id: cartId,
                cart_item_id: cartProduct.id
            }))
                .then(unwrapResult)
                .then(() => {
                    dispatch(toastSuccess("Product removed from cart successfully"));
                    setUpdatingCart(false);
                })
                .catch(() => {
                    setUpdatingCart(false);
                });
        }
    }

    useEffect(() => {
        if (cartProduct) {
            setCount(cartProduct.quantity);
        }
    }, [cartProduct]);

    useEffect(() => {
        if (initialLoad && cartProduct && cartProduct.quantity !== count) {
            addItemToCart();
        }
        setInitialLoad(true);
    }, [count]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setGallery(Array.isArray(image) ? image : [image].filter(i => i));
    }, [image]);

    useEffect(() => {
        if(description){
            setDesc(parse(description));
        }else{
            setDesc('');
        }
    }, [description]);

    const styles = {
        list: {
            // mainContainer: "w-100 p-3 d-flex position-relative product-card-container",
            // mainContainer: "w-100 p-3 d-flex position-relative product-card-container",
            // rxPill: "bg-danger text-white float-right f-10 pr-3 pl-3 pt-1 pb-1 rx-pill",
            // imgContainer: "w-15 d-flex align-items-center cursor-pointer",
            // img: "w-100",
            // content: "w-50 pr-3 pl-3 cursor-pointer",
            // name: "font-weight-bold f-15 mb-2 text-center",
            // manufacturer: "text-center text-secondary f-13 mb-2",
            // price: "text-center f-14",
            // actionContainer: "d-flex justify-content-around w-85 add-to-cart align-items-center",
            // actions: "d-flex w-50 justify-content-between",
            // qtyContainer: "d-flex align-items-center",
            // addToCartContainer: "d-flex align-items-center"
            mainContainer: "w-100 h-100 p-3 d-flex flex-column justify-content-between position-relative product-card-container",
            rxPill: "bg-danger text-white float-right f-13 pr-3 pl-3 pt-1 pb-1 rx-pill",
            imgContainer: "w-100 cursor-pointer",
            img: "w-100 mb-3",
            desc: "w-100 pt-6 border-bottom mb-11 list-unstyled f-10",
            content: "w-100 mb-2 cursor-pointer",
            name: "font-weight-bold f-16 mb-2",
            manufacturer: "text-center text-secondary f-13 mb-2",
            price: "text-center f-14",
            actionContainer: "d-flex flex-wrap w-100 justify-content-between pt-2 add-to-cart",
            actions: "d-flex w-100 justify-content-between",
            qtyContainer: "d-flex align-items-center",
            addToCartContainer: "d-flex align-items-center justify-content-end"
        },
        grid: {
            mainContainer: "w-100 h-100 p-3 d-flex flex-column justify-content-between position-relative product-card-container",
            rxPill: "bg-danger text-white float-right f-13 pr-3 pl-3 pt-1 pb-1 rx-pill",
            imgContainer: "resize-image-div cursor-pointer",
            img: "w-100 mb-3",
            desc: "w-100 pt-6 border-bottom mb-11 list-unstyled f-10",
            content: "w-100 mb-2 cursor-pointer",
            name: "font-weight-bold f-16 mb-2",
            manufacturer: "text-center text-secondary f-13 mb-2",
            price: "text-center f-14",
            actionContainer: "d-flex flex-wrap w-100 justify-content-between pt-2 add-to-cart",
            actions: "d-flex w-100 justify-content-between",
            qtyContainer: "d-flex align-items-center",
            addToCartContainer: "d-flex align-items-center justify-content-end"
        },
        detailed: {
            mainContainer: "col-12 p-3 d-flex flex-wrap position-relative product-card-container",
            rxPill: "bg-danger text-white float-right f-10 pr-3 pl-3 pt-1 pb-1 rx-pill",
            imgContainer: "col-12 col-sm-6 d-flex flex-column mb-4 mb-sm-0 p-0 p-sm-2",
            img: "w-100",
            content: "w-100",
            name: "font-weight-bold f-25 mb-2",
            manufacturer: "text-secondary f-15 mb-3",
            price: "f-25 font-weight-bold",
            actionContainer: "d-flex flex-column col-12 col-sm-6 add-to-cart",
            actions: "d-flex w-100 pt-3 pb-3",
            qtyContainer: "d-flex align-items-center mr-2",
            addToCartContainer: "d-flex align-items-center"
        },
    };



    return (
        <div
            className={styles[viewType].mainContainer}
        >
            {/* {console.log("View Type", viewType)} */}
            {
                rxRequired === 1 &&
                <div className="position-absolute w-100 rx-container">
                    <span className={styles[viewType].rxPill}>Rx Required</span>
                </div>
            }

            <div
                className={styles[viewType].imgContainer}
                onClick={() => {
                    // if (entity === "medicines" && !(viewType === "detailed") && productId) {
                    //     history.push("/" + entity + "/" + productId);
                    // }
                    if (!(viewType === "detailed") && productId) {
                        history.push("/" + "test" + "/" + productId);
                    }
                }}
            >
                {
                    viewType !== "detailed" &&
                    <img src={gallery.length > 0 ? gallery[0] : CategoryImage} alt={name} className={styles[viewType].img} />
                }
                {
                    viewType === "detailed" &&
                    <Carousel
                        itemsToShow={1}
                        pagination={false}
                    >
                        {
                            gallery.length > 0 ?
                                gallery.map((i, k) => <img src={i} alt={name} className={styles[viewType].img} key={k} />) :
                                <img src={CategoryImage} alt={name} className={styles[viewType].img} />
                        }
                        {/* {
                            gallery != null ?
                                <img src={gallery} alt={name} className={styles[viewType].img} /> :
                                <img src={CategoryImage} alt={name} className={styles[viewType].img} />
                        } */}
                    </Carousel>
                }
            </div>

            <div className={styles[viewType].actionContainer}>
                <div
                    className={styles[viewType].content}
                    onClick={() => {
                        // if (entity === "medicines" && !(viewType === "detailed") && productId) {
                        //     history.push("/" + entity + "/" + productId);
                        // }
                        if (!(viewType === "detailed") && productId) {
                            history.push("/" + "test" + "/" + productId);
                        }
                    }}
                >
                    <h6 className={styles[viewType].name}>{name}</h6>
                    {
                        manufacturer &&
                        <p className={styles[viewType].manufacturer}><i>Mfr: {manufacturer}</i></p>
                    }
                    <div className={styles[viewType].desc}>
                        {/* <ul className="list-unstyled">
                            <li>This Test 1 desc</li>
                            <li>This Test 2 desc</li>
                            <li>This Test 3 desc</li>
                        </ul> */}
                        {
                            desc && desc.length > 200 ? desc.slice(0, 200) : desc
                        }
                        {
                            desc && desc.length > 200 ? <div><a href='javascript:void(0)'>...Read More</a></div> : null
                        }
                    </div>
                    {/* <p className={styles[viewType].price}>₹ {price}</p> */}
                </div>
                <div className={styles[viewType].actions}>
                    <p className={styles[viewType].price} style={{ paddingTop: '10px' }}>₹ {price}</p>

                    {/* {entity === "medicines" ? <div className={styles[viewType].qtyContainer}>
                        <button type="button" className="inc-dec pl-2 pr-2 pt-1 pb-1" onClick={() => setCount(c => c - 1 < 1 ? 1 : c - 1)}> - </button>
                        <span className="p-2">{count}</span>
                        <button type="button" className="inc-dec pl-2 pr-2 pt-1 pb-1" onClick={() => setCount(c => c + 1)}> + </button>
                    </div> : <div>&nbsp;</div>} */}
                    <div className={styles[viewType].addToCartContainer}>
                        {
                            <button type="button" className={!cartProduct ? 'btn' : 'd-none'} onClick={() => addItemToCart(true)} title="Add to cart">
                                {
                                    viewType === "detailed" && <span className="mr-2">Add to cart</span>
                                }
                                <CircularProgress size={14} className={updatingCart ? "text-white align-middle" : "d-none"} />
                                <AddShoppingCart className={!updatingCart ? "icon" : "d-none"} />
                            </button>
                        }
                        {
                            cartProduct &&
                            <button type="button" className={cartProduct ? 'btn' : 'd-none'} onClick={removeItemFromCart} title="Remove from cart">
                                {
                                    viewType === "detailed" && <span className="mr-2">Remove</span>
                                }
                                <CircularProgress size={14} className={updatingCart ? "text-white align-middle" : "d-none"} />
                                <RemoveShoppingCart className={!updatingCart ? "icon" : "d-none"} />
                            </button>
                        }
                    </div>
                </div>
                {/* {
                    desc && (
                        <div className="w-100 pt-4 pb-4 product-description">
                            <h1 className="mb-3">About this item: </h1>
                            {desc}
                        </div>
                    )
                } */}
            </div>
        </div>
    );
}