import React, { useState, useEffect, useMemo } from 'react';
import { CategoryCard } from 'components/Category';
import Carousel from 'react-elastic-carousel';
import { useWindowSize } from 'helpers';

export const CategoryCarousel = ({categories}) => {
    const breakPoints = useMemo(() => (
        [
            { width: 300, itemsToShow: 1 },
            { width: 400, itemsToShow: 2 },
            { width: 500, itemsToShow: 3 },
            { width: 700, itemsToShow: 4 },
            { width: 900, itemsToShow: 5 }
        ]
    ),[]);
    const windowSize = useWindowSize();
    const [missingCategories,setMissingCategories] = useState([]);

    useEffect(() => {
        let currentBreakPoint = 
            breakPoints.find(breakPoint => breakPoint.width >= windowSize.width) || 
            { 
                width: 900, 
                itemsToShow: 5
            };

        const itemsToShow = currentBreakPoint.itemsToShow;

        if(categories.length < itemsToShow){
            setMissingCategories(Array.from(Array(itemsToShow - categories.length).keys()))
        } else {
            setMissingCategories([]);
        }
    }, [windowSize, categories.length, breakPoints]);
      
      
    return (
        <>
            {
                categories.length > 0 && 
                <Carousel
                    breakPoints={breakPoints}
                    pagination={false}
                >
                    {
                        categories.map((category, i) => (
                            <div className="mr-4 ml-4 w-100 h-100" key={'carousel_categories_' + i}>
                                <CategoryCard category={category.name} image={category.image_url} />
                            </div>
                        ))
                    }
                    {
                        missingCategories.map(missingCategory => <div key={"missing_category_" + missingCategory}></div>)
                    }
                </Carousel>
            }
        </>
    );
}