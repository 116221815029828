import { http } from 'api/http';

export const GET_TESTS = "guest/search/labtest";
export const GET_LAB_TEST = "getLabtest";

export const getTests = (payload = {}) => {
    return http.get(GET_TESTS, {
        params: payload
    });
}

export const viewLabTest = (id) => {
    return http.get(GET_LAB_TEST + "/" + id);
}