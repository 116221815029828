import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login, resendEmailOtp } from 'api/auth';
import { toastError } from 'redux/Toast';
import { logout } from 'redux/Me';

export const Login = ({
    updateLoggedInUserAndRedirect,
    redirectToPrescription,
    goToRegister,
    goToVerifyOtp
}) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();
    const profile = useSelector(state => state.me.profile);

    const authenticateUser = async () => {
        try {
            const response = await login({
                username, password
            });
            updateLoggedInUserAndRedirect(response);
        } catch (e) {
            console.log(e);
            if(e.action === 'redirect'){
                await resendEmailOtp({ email: e.errors.email[0]})
                localStorage.registered_email = e.errors.email[0];
                goToVerifyOtp();
            }else{
                dispatch(toastError(e.message));
            }
        }
    }

    return (
        <>
            {
                profile &&
                <>
                    <p className="mb-3">
                        You are logged in as <b>{[profile.first_name, profile.middle_name, profile.last_name].filter(n => n).join(" ")}</b>.
                        <span className="cursor-pointer pl-2 pr-2 text-theme" onClick={() => dispatch(logout())}>
                            <u>Log in as different user?</u>
                        </span>
                    </p>
                    <div className="d-flex">
                        <button
                            className="btn btn-primary bg-theme mr-3 f-14"
                            onClick={redirectToPrescription}
                        >
                            Next
                        </button>
                    </div>
                </>
            }
            {
                !profile && 
                <div className="w-100">
                    <input
                        type="text"
                        className="form-control pt-4 pb-4 mb-2"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="Email/Username"
                        autoComplete="new-password"
                    />
                    <input
                        type="password"
                        className="form-control pt-4 pb-4 mb-3"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                        autoComplete="new-password"
                    />
                    <div className="d-flex align-items-center">
                        <button
                            className="btn btn-primary bg-theme mr-3 f-14"
                            onClick={authenticateUser}
                            disabled={!username || !password}
                        >Login</button>
                        <span
                            className="cursor-pointer f-13"
                            onClick={goToRegister}
                        ><u>Don't have an account?</u> </span>
                    </div>
                </div>
            }
        </>
    )
}