import React from 'react';
import './SectionContainer.scss';

export const SectionContainer = (props) => {
    return (
        <div className="w-100 section-container pt-4 pb-4">
            <h2 className="pt-2 pb-2 mb-4">
                {props.title}
                {
                    props.action && 
                    <button className="float-right btn btn-primary bg-theme f-13" onClick={() => props.action()}>
                        View All
                    </button>
                }
            </h2>
            <div className="w-100">
                {props.children}
            </div>
        </div>
    )
}