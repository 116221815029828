import React, { useState, useEffect, createRef } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { MainContainer } from "./components/Containers";
import { CategoryPopup } from "./components/Category";
import { ScrollToTop } from "./components/Core";
import { Entity } from "context";
import { useDispatch } from "react-redux";
import { fetchCategories } from "redux/Category";
import {
  createEmptyCart,
  fetchCartDetails,
  initializeCartId,
} from "redux/Cart";
import { set as setHeaderHeight } from "redux/Layout";
import { setAccessToken, fetchProfile } from "redux/Me";
import { resetToastMessage } from "redux/Toast";
import {
  Home,
  Medicines,
  Tests,
  CartItems,
  CheckoutPage,
  CheckoutSuccess,
  Medicine,
} from "./pages";
import { Test } from "pages/Products/Test";
import { BrowserRouter } from "react-router-dom/cjs/react-router-dom.min";

function App() {
  const { addToast } = useToasts();
  const ref = createRef();
  const dispatch = useDispatch();
  const location = useLocation();
  if (localStorage.medicines_cart_id) {
    dispatch(
      initializeCartId({
        rx_dispatch_type: "medicines",
        cart_id: localStorage.medicines_cart_id,
      })
    );
  }
  if (localStorage.tests_cart_id) {
    dispatch(
      initializeCartId({
        rx_dispatch_type: "tests",
        cart_id: localStorage.tests_cart_id,
      })
    );
  }
  const [entity, setEntity] = useState("medicines");
  const cartId = useSelector((state) => state.cart[entity].cart_id);
  const cartLoading = useSelector((state) => state.cart[entity].loading);
  const categoriesLoading = useSelector((state) => state.categories.loading);
  const categories = useSelector((state) => state.categories.data);
  const showCategoryPopup = useSelector((state) => state.showCategoryPopup);
  const toastSuccessMsg = useSelector((state) => state.toast.success);
  const toastErrorMsg = useSelector((state) => state.toast.error);

  useEffect(() => {
    if (process.env.REACT_APP_GTM_ID) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.innerText = `(function (w, d, s, l, i) { w[l] = w[l] || []; w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' }); var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f); })(window, document, 'script', 'dataLayer', '${process.env.REACT_APP_GTM_ID}')`;
      document.head.appendChild(script);
    }
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_HELP_SCOUT_KEY) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.innerText = `!function(e,t,n){function a(){
      var e=t.getElementsByTagName("script")[0],n=t.createElement("script");
      n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});`;
      document.head.appendChild(script);
      window.Beacon("init", process.env.REACT_APP_HELP_SCOUT_KEY);
    }
  }, []);

  useEffect(() => {
    if (categoriesLoading) {
      dispatch(
        fetchCategories({
          paginate: 0,
        })
      );
    }
  }, [dispatch, categoriesLoading]);

  useEffect(() => {
    if (ref.current) {
      dispatch(setHeaderHeight(ref.current.offsetHeight));
    }
  }, [ref, dispatch]);

  useEffect(() => {
    if (!cartId) {
      dispatch(
        createEmptyCart({
          type: entity === "medicines" ? "MED" : "LAB",
          rx_dispatch_type: entity,
        })
      );
    }

    if (cartLoading && cartId) {
      dispatch(
        fetchCartDetails({
          id: cartId,
          rx_dispatch_type: entity,
        })
      );
    }
  }, [dispatch, cartId, cartLoading, entity]);

  useEffect(() => {
    if (toastSuccessMsg) {
      addToast(toastSuccessMsg, { appearance: "success" });
      dispatch(resetToastMessage());
    }
  }, [toastSuccessMsg, dispatch, addToast]);

  useEffect(() => {
    if (toastErrorMsg) {
      addToast(toastErrorMsg, { appearance: "error" });
      dispatch(resetToastMessage());
    }
  }, [toastErrorMsg, dispatch, addToast]);

  useEffect(() => {
    if (location.pathname.includes("/medicines")) {
      if (entity !== "medicines") {
        setEntity("medicines");
      }
    } else if (location.pathname.includes("/tests")) {
      if (entity !== "tests") {
        setEntity("tests");
      }
    }
  }, [location.pathname, entity]);

  useEffect(() => {
    if (localStorage.access_token) {
      dispatch(setAccessToken(localStorage.access_token));
      dispatch(fetchProfile());
    }
  }, [dispatch]);

  return (
    <Entity.Provider value={entity}>
      <Header ref={ref} />
      <MainContainer>
        <ScrollToTop>
          <Switch>
            <Route path="/medicines/tests">
              <Tests />
            </Route>
            <Route path="/medicines/medicines">
              <Home />
            </Route>

            <Route path="/:entity/checkout/:quoteId/success">
              <CheckoutSuccess />
            </Route>
            <Route path="/:entity/checkout">
              <CheckoutPage />
            </Route>
            <Route path="/:entity/cart">
              <CartItems />
            </Route>
            <Route path="/medicines/search">
              <Medicines />
            </Route>
            <Route path="/medicines/:medicineId">
              <Medicine />
            </Route>
            <Route path="/tests/search">
              <Tests />
            </Route>
            <Route path="/tests">
              <Tests />
            </Route>
            <Route path="/test/:testId">
              <Test />
            </Route>
            <Route path="/medicines">
              <Home />
            </Route>
            <Redirect from="/" to="/medicines" />
          </Switch>
        </ScrollToTop>
      </MainContainer>
      <Footer />
      <CategoryPopup
        showCategoryPopup={showCategoryPopup}
        categories={categories}
      />
    </Entity.Provider>
  );
}

export default App;
