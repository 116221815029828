import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Category, ShoppingCart } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Entity } from 'context';
import { show as showCategory } from 'redux/Category';
import './HeaderBottom.scss';

export const HeaderBottom = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const entity = useContext(Entity);
    const cartCount = useSelector(state => state.cart[entity].count);

    return (
        <div className="w-100 d-flex header-bottom justify-content-between">
            {
                entity === 'medicines' &&
                <div
                    className="col-2 category pt-md-4 pb-md-4 pt-3 pb-3 cursor-pointer d-flex align-items-center justify-content-center"
                    onClick={() => dispatch(showCategory())}
                >
                    <p className="d-flex align-items-center">
                        <Category className="icon mr-md-3" />
                        <span className="d-none d-md-block">Select&nbsp;</span><span className="d-none d-sm-block">Category</span>
                    </p>
                </div>
            }
            <div className="col-lg-9 col-md-8 d-flex align-items-center menus pl-md-4 pr-md-4 pl-2 pr-2">
                <Link to="/medicines" className={entity === 'medicines' ? 'text-theme pr-3 pl-3' : 'pr-3 pl-3'}>Medicines</Link>
                <Link to="/tests" className={entity === 'tests' ? 'text-theme pr-3 pl-3' : 'pr-3 pl-3'}>Tests</Link>
            </div>
            <div 
                className="cart col-lg-1 col-2 pt-md-4 pb-md-4 pt-3 pb-3 d-flex align-items-center justify-content-center cursor-pointer"
                onClick={() => history.push('/' + entity + '/cart')}
            >
                <p className="text-center d-flex align-items-center">
                    <ShoppingCart className="icon mr-sm-1" />
                    <span className="d-none d-sm-block">Cart</span> {cartCount > 0 && '(' + cartCount + ')'}
                </p>
            </div>
        </div>
    );
}